/* General Styles */
body {
    font-family: "Inter", sans-serif;
    margin: 0;
    padding: 0;
    color: #333;
    background-color: #f8f9fa;
    /* Light background for the page */
}

.domain-listing-container {
    max-width: 1200px;
    /* Limit the container width */
    margin: 0 auto;
    /* Center the container */
    padding: 20px;
}

.domain-listing-container .domain-listing-title {
    text-align: center;
    color: #2c3e50;
    font-size: 2.5rem;
    margin-bottom: 30px;
    font-weight: 700;
    text-transform: capitalize;
}

/* Filters Section */
.filters {
    display: flex;
    justify-content: space-between;
    gap: 15px;
    margin-bottom: 40px;
    flex-wrap: wrap;
    background-color: #fff;
    padding: 20px;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.filters input,
.filters select {
    padding: 12px;
    font-size: 16px;
    border: 1px solid #e0e0e0;
    border-radius: 8px;
    flex: 1;
    min-width: 200px;
    background-color: #f8f9fa;
    transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.filters input:focus,
.filters select:focus {
    border-color: #007bff;
    outline: none;
    box-shadow: 0 0 8px rgba(0, 123, 255, 0.3);
}

/* Card Layout Styles */
.domain-card-container {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 20px;
    padding: 20px 0;
    max-width: 1200px;
    margin: 0 auto;
}

.domain_card {
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
    overflow: hidden;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
    animation: fadeIn 0.5s ease-in-out;
    /* Fade-in animation */
}

@keyframes fadeIn {
    from {
        opacity: 0;
        transform: translateY(20px);
    }

    to {
        opacity: 1;
        transform: translateY(0);
    }
}

.domain_card:hover {
    transform: translateY(-5px);
    box-shadow: 0 8px 24px rgba(0, 0, 0, 0.15);
}

.domain_card img {
    width: 100%;
    height: auto;
    object-fit: cover;
}

.domain-card-content {
    padding: 15px 5px;
}

.domain-name-price-div{
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.domain-card-content h3 {
    font-size: 1rem;
    font-weight: 600;
    color: #2c3e50;
    /* margin-bottom: 8px; */
}

.domain-card-content p {
    font-size: 0.875rem;
    color: #666;
    margin-bottom: 8px;
}

.domain-card-content .domain-price {
    font-size: 17px!important;
    font-weight: 700;
    color: #3954ff;
    margin: 0;
    padding: 3px 5px;
    /* margin-bottom: 8px; */
}

.domain-card-content .domain-date {
    font-size: 0.75rem;
    color: #999;
}

.domain-card-actions {
    padding: 8px 10px;
    /* border-top: 1px solid #e0e0e0; */
    /* text-align: center; */
    display: flex;
    gap: 10px;
    /* justify-content: space-between; */
}

.domain-card-actions .buy-button {
    padding: 0px 0px;
    text-decoration: underline;
    background-color: transparent;
    background: linear-gradient(159deg, #3954ff 0, #617eff 75%, #c0e0ff 100%);
    color: #000;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 400;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.domain-card-actions .buy-button:hover {
    background-color: transparent;
}

/* No Results Message */
.no-results {
    text-align: center;
    color: #666;
    font-size: 1.2rem;
    margin-top: 20px;
    padding: 20px;
    background-color: #fff;
    border-radius: 12px;
    box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

/* Pagination Styles */
.pagination {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 20px;
    gap: 10px;
}

.pagination button {
    padding: 8px 16px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 8px;
    font-size: 14px;
    font-weight: 600;
    cursor: pointer;
    transition: background-color 0.3s ease;
}

.pagination button:disabled {
    background-color: #ccc;
    cursor: not-allowed;
}

.pagination button:hover:not(:disabled) {
    background-color: #0056b3;
}

.pagination span {
    font-size: 16px;
    color: #333;
}
/* Add these styles at the bottom */
.filters button {
    padding: 12px 24px;
    border: none;
    border-radius: 8px;
    cursor: pointer;
    transition: all 0.3s ease;
    font-size: 14px;
}

.filters .apply-button {
    background-color: #007bff;
    color: white;
}

.filters .apply-button:hover {
    background-color: #0056b3;
}

.filters .remove-button {
    background-color: #dc3545;
    color: white;
    margin-left: 8px;
}

.filters .remove-button:hover {
    background-color: #bb2d3b;
}

/* Adjust filter items for mobile */
@media (max-width: 768px) {
    .filters button {
        width: 100%;
        margin-top: 10px;
    }
    .filters .remove-button {
        margin-left: 0;
    }
}

/* Responsive Design */
@media (max-width: 768px) {
    .domain-card-container {
        grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
        /* Smaller cards on mobile */
    }

    .domain-card img {
        height: 120px;
        /* Smaller image height on mobile */
    }

    .filters {
        flex-direction: column;
    }

    .filters input,
    .filters select {
        width: 100%;
    }
}